// src/hooks.js
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import history from "./history";
import { useDispatch } from "react-redux";
import { get_user_privileges } from "./services/userPrivelegesServices";
import { SET_PRIVILEGES } from "./redux/constants/priveleges-types";

export const useBlocker = (blocker, when = true) => {
  useEffect(() => {
    if (!when) return;
    const unblock = history.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [blocker, when]);
};

export const useCallbackPrompt = (when) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location.pathname]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};

export const useFetchPrivileges = () => {
  const dispatch = useDispatch();

  const fetchPrivileges = useCallback(async () => {
    try {
      const response = await get_user_privileges();
      dispatch({ type: SET_PRIVILEGES, payload: response.permissions });
    } catch (error) {
      console.error('Error fetching user privileges:', error);
    }
  }, [dispatch]);

  return fetchPrivileges;
};